import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PrintContextConsumer } from 'react-to-print'
import Loader from 'components/Loader'
import ReportContainer from 'components/ReportContainer'
import ScrapTrendReportHeader from 'reports/scrapTrend/ScrapTrendReportHeader'
import ScrapTrendReportGraph from 'reports/scrapTrend/ScrapTrendReportGraph'

const ScrapTrendReport = ({ isLoaded }) => {
  if (isLoaded) {
    return (
      <ReportContainer selector='#scrap-trend-report.report'>
        {({ isPrinting }) => (
          <React.Fragment>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <ScrapTrendReportHeader handlePrint={handlePrint} />
              )}
            </PrintContextConsumer>
            <ScrapTrendReportGraph isPrinting={isPrinting} />
          </React.Fragment>
        )}
      </ReportContainer>
    )
  } else {
    return <Loader full />
  }
}

ScrapTrendReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(ScrapTrendReport)
