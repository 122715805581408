import React from 'react'
import MultiSelect from 'components/MultiSelect'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import DropdownButton from 'components/DropdownButton'
import {
  showAllProducts,
  hideAllProducts,
  showProduct,
  hideProduct
} from './actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectProductOptions,
  selectVisibleProductIds,
  selectProductIds
} from './selectors'

const mapStateToSelectProps = state => {
  return {
    options: selectProductOptions(state),
    columnsCount: 2,
    singleColumnWidth: 1120
  }
}

const mapDispatchToSelectProps = {
  onSelect: showProduct,
  onDeselect: hideProduct
}

const Select = connect(
  mapStateToSelectProps,
  mapDispatchToSelectProps
)(MultiSelect)

const ScrapTrendProductsDropdown = ({
  showAllProducts,
  hideAllProducts,
  productsCount
}) => {
  return (
    <div className='report-header__navigation-item'>
      <DropdownButton
        color='default'
        className='btn--flex'
        popperConfig={{ placement: 'bottom-end' }}
        content={() => (
          <div className='multi-select-box multi-select-box--realization-menu'>
            <div className='multi-select-box__actions'>
              <Button size='small' color='secondary' onClick={showAllProducts}>
                <FormattedMessage id='reports.scrapTrend.selectAll' />
              </Button>
              <Button size='small' color='secondary' onClick={hideAllProducts}>
                <FormattedMessage id='reports.scrapTrend.selectNone' />
              </Button>
            </div>

            <div className='multi-select-box__select'>
              <Select />
            </div>
          </div>
        )}
      >
        {productsCount < 0 && (
          <FormattedMessage id='reports.scrapTrend.allProducts' />
        )}
        {productsCount === 0 && (
          <FormattedMessage id='reports.scrapTrend.noneProduct' />
        )}
        {productsCount > 0 && (
          <FormattedMessage
            id='reports.scrapTrend.nProducts'
            values={{ n: productsCount }}
          />
        )}
      </DropdownButton>
    </div>
  )
}

ScrapTrendProductsDropdown.propTypes = {
  showAllProducts: PropTypes.func,
  hideAllProducts: PropTypes.func,
  productsCount: PropTypes.number
}

const mapStateToProps = state => {
  const visibleProductsCount = selectVisibleProductIds(state).length
  const productsCount = selectProductIds(state).length

  return {
    productsCount:
      productsCount === visibleProductsCount ? -1 : visibleProductsCount
  }
}

const mapDispatchToProps = {
  showAllProducts,
  hideAllProducts
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrapTrendProductsDropdown)
