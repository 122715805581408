import {
  fork,
  call,
  all,
  put,
  select,
  takeLatest,
  race,
  take,
  delay
} from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import Actions, {
  startInit,
  finishInit,
  receiveReports,
  receiveLocations
} from 'reports/scrapTrend/actions'
import heartbeat from 'lib/saga/heartbeat'
import { selectToken, selectTimezone } from 'reports/scrapTrend/selectors'
import {
  SET_NAVIGATION,
  setNavigationDate,
  configureNavigation
} from 'containers/Navigation/actions'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { TYPE_MONTHLY_RANGE } from 'containers/Navigation/const'
import {
  selectVisibleLocationIds,
  selectVisibleProductIds,
  selectVisibleProjectIds
} from './selectors'
import { receiveProducts, receiveProjects } from './actions'

const AUTORELOAD_INTERVAL = 60 * 1000

function * init () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  yield put(startInit())

  const [locations, products, projects] = yield all([
    call(api.get, '/locations', { token }),
    call(api.get, '/products', { token }),
    call(api.get, '/projects?include_inactive=true', { token })
  ])

  yield put(configureNavigation(TYPE_MONTHLY_RANGE))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  yield put(receiveLocations(locations))
  yield put(receiveProducts(products))
  yield put(receiveProjects(projects))

  yield put(finishInit())
}

function * reports () {
  const type = new URLSearchParams(window.location.search).get('type')
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const timeParams = yield select(selectNavigationRange, timezone)
  const locationIds = (yield select(selectVisibleLocationIds)).join(',')

  let filterIds
  if (type === 'products') {
    filterIds = (yield select(selectVisibleProductIds)).join(',')
  }
  if (type === 'projects') {
    filterIds = (yield select(selectVisibleProjectIds)).join(',')
  }
  const params = {
    ...timeParams,
    locationIds,
    [`${type.slice(0, -1)}Ids`]: filterIds,
    [`${type}Filter`]: 1
  }

  const reports = yield call(api.get, '/reports/scrap_trend', { params, token })

  yield put(receiveReports(reports))
}

function * reload () {
  let result

  while (true) {
    result = yield race({
      action: take([
        Actions.SHOW_LOCATION,
        Actions.HIDE_LOCATION,
        SET_NAVIGATION
      ]),
      timeout: delay(AUTORELOAD_INTERVAL)
    })

    if (result.timeout) {
      yield call(reports)
    }
  }
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Reports:ScrapTrend')
  }

  yield call(init)
  yield call(reports)
  yield takeLatest(
    [
      Actions.SHOW_LOCATION,
      Actions.HIDE_LOCATION,
      Actions.SHOW_ALL_LOCATIONS,
      Actions.HIDE_ALL_LOCATIONS,
      Actions.SHOW_PRODUCT,
      Actions.HIDE_PRODUCT,
      Actions.SHOW_ALL_PRODUCTS,
      Actions.HIDE_ALL_PRODUCTS,
      Actions.SHOW_PROJECT,
      Actions.HIDE_PROJECT,
      Actions.SHOW_ALL_PROJECTS,
      Actions.HIDE_ALL_PROJECTS,
      SET_NAVIGATION
    ],
    reports
  )

  yield fork(reload)
}
