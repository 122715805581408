import React from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  ReferenceLine,
  LabelList,
  Tooltip,
  Legend
} from 'recharts'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectReports } from 'reports/scrapTrend/selectors'
import { FormattedMessage } from 'react-intl'
import { selectValueDomain, selectPercentageDomain } from './selectors'
import { isEqual } from 'lodash'

const percentageFormatter = val => `${(val * 100).toFixed(2)} %`
const valueFormatter = value => `${(value / 1000.0).toFixed(2)}k`
const monthFormatter = month => moment(month).format('MMM YYYY')
const valueColorPicker = value => (value <= 0 ? '#30AD64' : '#BF3A31')

const legendFormatter = value => {
  switch (value) {
    case 'threshold':
      return <FormattedMessage id='reports.scrapTrend.threshold' />
    case 'percentage':
      return <FormattedMessage id='reports.scrapTrend.scrapActual' />
    case 'value':
      return <FormattedMessage id='reports.scrapTrend.scrapVariance' />
    default:
      return value
  }
}

const ScrapTrendTooltipValue = ({ color, name, value }) => (
  <div className='recharts-custom-tooltip__value'>
    <div
      className='recharts-custom-tooltip__dot'
      style={{ backgroundColor: color }}
    />
    {name}: {value}
  </div>
)

ScrapTrendTooltipValue.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
}

const ScrapTrendTooltip = ({ payload }) => {
  if (!payload || !payload[0]) {
    return null
  }

  const { month, value, percentage, currency, threshold } = payload[0].payload

  return (
    <div className='recharts-custom-tooltip recharts-custom-tooltip--dots'>
      <div className='recharts-custom-tooltip__label'>
        {monthFormatter(month)}
      </div>
      <FormattedMessage
        id='reports.scrapTrend.scrapActual'
        defaultMessage='Scrap-Actual [%]: '
        description='Label of actual scrap percentage'
      >
        {text => (
          <ScrapTrendTooltipValue
            name={text}
            color='#2F81B7'
            value={percentageFormatter([percentage])}
          />
        )}
      </FormattedMessage>

      <FormattedMessage
        id='reports.scrapTrend.scrapVariance'
        defaultMessage='Scrap-Variance: '
        description='Label of actual scrap value'
      >
        {text => (
          <ScrapTrendTooltipValue
            name={text}
            color={valueColorPicker(value)}
            value={`${currency}${valueFormatter(value)}`}
          />
        )}
      </FormattedMessage>

      <FormattedMessage
        id='reports.scrapTrend.threshold'
        defaultMessage='Scrap-Variance: '
        description='Label of actual scrap value'
      >
        {text => (
          <ScrapTrendTooltipValue
            name={text}
            color='#000000'
            value={percentageFormatter(threshold)}
          />
        )}
      </FormattedMessage>
    </div>
  )
}

ScrapTrendTooltip.propTypes = {
  payload: PropTypes.array
}

class ScrapTrendGraph extends React.Component {
  constructor () {
    super()
    this.state = { animate: true }
  }

  getSnapshotBeforeUpdate (prevProps) {
    const shouldAnimate = !isEqual(prevProps.data, this.props.data)
    return shouldAnimate
  }

  componentDidUpdate (_prevProps, prevState, shouldAnimate) {
    if (prevState.animate !== shouldAnimate) {
      this.setState({ animate: shouldAnimate }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  render () {
    const { data, percentageDomain, valueDomain, isPrinting } = this.props
    const height = isPrinting ? 600 : 700

    return (
      <div
        className='report-recharts-container'
        style={{ width: '100%', height, marginTop: 18, marginBottom: 18 }}
      >
        <ResponsiveContainer>
          <ComposedChart
            width={600}
            height={400}
            data={data}
            margin={{ top: 20, right: 20, bottom: 120, left: 20 }}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <ReferenceLine y={0} stroke='#000' yAxisId='left' />
            <XAxis
              dataKey='month'
              interval={0}
              tickFormatter={monthFormatter}
            />
            <YAxis
              yAxisId='right'
              orientation='right'
              dataKey='percentage'
              domain={percentageDomain}
              tickFormatter={percentageFormatter}
              tickCount={11}
            />
            <YAxis
              yAxisId='left'
              orientation='left'
              dataKey='value'
              tickCount={11}
              domain={valueDomain}
            />
            <Legend formatter={legendFormatter} />
            <Bar
              yAxisId='right'
              dataKey='percentage'
              barSize={40}
              fill='#2F81B7'
              isAnimationActive={this.state.animate}
            >
              {data.map(entry => (
                <Cell key={`cell-percentage-${entry.month}`} fill='#2F81B7' />
              ))}
              <LabelList position='top' formatter={percentageFormatter} />
            </Bar>
            <Bar
              yAxisId='left'
              dataKey='value'
              barSize={40}
              fill='#30AD64'
              isAnimationActive={this.state.animate}
            >
              {data.map(entry => (
                <Cell
                  key={`cell-value-${entry.month}`}
                  fill={valueColorPicker(entry.value)}
                />
              ))}
              <LabelList position='top' formatter={valueFormatter} />
            </Bar>
            <Line
              yAxisId='right'
              type='monotone'
              dataKey='threshold'
              stroke='#000000'
              isAnimationActive={this.state.animate}
              animationDuration={500}
              strokeWidth={3}
              dot={{ r: 5, fill: '#fff', strokeWidth: 3, stroke: '#000000' }}
              activeDot={{ r: 5, fill: '#000000', strokeWidth: 0 }}
            />
            <Tooltip content={<ScrapTrendTooltip />} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

ScrapTrendGraph.propTypes = {
  data: PropTypes.array,
  percentageDomain: PropTypes.arrayOf(PropTypes.number),
  valueDomain: PropTypes.arrayOf(PropTypes.number),
  isPrinting: PropTypes.bool
}

const mapStateToProps = state => ({
  data: selectReports(state),
  valueDomain: selectValueDomain(state),
  percentageDomain: selectPercentageDomain(state)
})

export default connect(mapStateToProps)(ScrapTrendGraph)
