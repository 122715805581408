import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_REPORTS: 'RECEIVE_REPORTS',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  RECEIVE_PRODUCTS: 'RECEIVE_PRODUCTS',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  SHOW_LOCATION: 'SHOW_LOCATION',
  HIDE_LOCATION: 'HIDE_LOCATION',
  SHOW_ALL_LOCATIONS: 'SHOW_ALL_LOCATIONS',
  HIDE_ALL_LOCATIONS: 'HIDE_ALL_LOCATIONS',
  SHOW_PRODUCT: 'SHOW_PRODUCT',
  HIDE_PRODUCT: 'HIDE_PRODUCT',
  SHOW_ALL_PRODUCTS: 'SHOW_ALL_PRODUCTS',
  HIDE_ALL_PRODUCTS: 'HIDE_ALL_PRODUCTS',
  SHOW_PROJECT: 'SHOW_PROJECT',
  HIDE_PROJECT: 'HIDE_PROJECT',
  SHOW_ALL_PROJECTS: 'SHOW_ALL_PROJECTS',
  HIDE_ALL_PROJECTS: 'HIDE_ALL_PROJECTS'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveReports = createAction(Actions.RECEIVE_REPORTS)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const receiveProducts = createAction(Actions.RECEIVE_PRODUCTS)
export const receiveProjects = createAction(Actions.RECEIVE_PROJECTS)
export const showLocation = createAction(Actions.SHOW_LOCATION)
export const hideLocation = createAction(Actions.HIDE_LOCATION)
export const showAllLocations = createAction(Actions.SHOW_ALL_LOCATIONS)
export const hideAllLocations = createAction(Actions.HIDE_ALL_LOCATIONS)
export const showProduct = createAction(Actions.SHOW_PRODUCT)
export const hideProduct = createAction(Actions.HIDE_PRODUCT)
export const showAllProducts = createAction(Actions.SHOW_ALL_PRODUCTS)
export const hideAllProducts = createAction(Actions.HIDE_ALL_PRODUCTS)
export const showProject = createAction(Actions.SHOW_PROJECT)
export const hideProject = createAction(Actions.HIDE_PROJECT)
export const showAllProjects = createAction(Actions.SHOW_ALL_PROJECTS)
export const hideAllProjects = createAction(Actions.HIDE_ALL_PROJECTS)
