import { createSelector } from 'reselect'

export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectPlant = state => state.plant
export const selectNavigation = state => state.navigation
export const selectNavigationDate = state => state.navigation.date
export const selectReports = state => state.reports
export const selectLocationIds = state => state.locations.allIds
export const selectLocationVisibleById = state => state.locations.isVisibleById
export const selectLocationsById = state => state.locations.allById
export const selectProductIds = state => state.products.allIds
export const selectProductVisibleById = state => state.products.isVisibleById
export const selectProductsById = state => state.products.allById
export const selectProductIdsByLocation = state => state.products.idsByLocation
export const selectProjectIds = state => state.projects.allIds
export const selectProjectVisibleById = state => state.projects.isVisibleById
export const selectProjectsById = state => state.projects.allById
export const selectProjectIdsByLocation = state => state.projects.idsByLocation

export const selectValueDomain = createSelector(selectReports, reports => {
  const maxAbsValue = Math.max(...reports.map(r => Math.abs(r.value)))
  const borderValue = Math.ceil(maxAbsValue / 1000) * 1000
  return [-borderValue, borderValue]
})

export const selectPercentageDomain = createSelector(selectReports, reports => {
  const maxAbsValue = Math.max(...reports.map(r => Math.abs(r.percentage)))
  const borderValue = (Math.ceil((maxAbsValue * 100) / 7.5) * 7.5) / 100
  return [-borderValue, borderValue]
})

export const selectVisibleLocationIds = createSelector(
  selectLocationVisibleById,
  mapperObject => {
    return Object.entries(mapperObject)
      .filter(([_id, isVisible]) => isVisible)
      .map(e => +e[0])
  }
)

export const selectLocationOptions = createSelector(
  selectLocationIds,
  selectLocationsById,
  selectLocationVisibleById,
  (locationIds, locationsById, visibleById) => {
    return locationIds.map(locationId => {
      const location = locationsById[locationId]

      return {
        label: location.name,
        value: locationId,
        selected: visibleById[locationId]
      }
    })
  }
)

export const selectVisibleProjectIds = createSelector(
  selectProjectVisibleById,
  selectVisibleLocationIds,
  selectProjectIdsByLocation,
  (mapperObject, visibleLocationIds, projectIdsByLocation) => {
    return Object.entries(mapperObject)
      .filter(
        ([id, isVisible]) =>
          visibleLocationIds.some(locationId =>
            projectIdsByLocation[locationId].includes(+id)
          ) && isVisible
      )
      .map(e => e[0])
  }
)

export const selectAvailableProductsById = createSelector(
  selectProductsById,
  selectVisibleLocationIds,
  selectProductIdsByLocation,
  (productsById, visibleLocationIds, productIdsByLocation) => {
    const result = {}

    visibleLocationIds.forEach(locationId => {
      productIdsByLocation[locationId].forEach(productId => {
        result[productId] = productsById[productId]
      })
    })

    return result
  }
)

export const selectProductOptions = createSelector(
  selectProductIds,
  selectAvailableProductsById,
  selectProductVisibleById,
  (productIds, productsById, visibleById) => {
    return productIds
      .map(productId => {
        const product = productsById[productId]

        if (!product) {
          return null
        }

        return {
          label: product.description,
          value: productId,
          selected: visibleById[productId]
        }
      })
      .filter(e => e !== null)
  }
)

export const selectVisibleProductIds = createSelector(
  selectProductVisibleById,
  selectVisibleLocationIds,
  selectProductIdsByLocation,
  (mapperObject, visibleLocationIds, productIdsByLocation) => {
    return Object.entries(mapperObject)
      .filter(
        ([id, isVisible]) =>
          visibleLocationIds.some(locationId =>
            productIdsByLocation[locationId].includes(+id)
          ) && isVisible
      )
      .map(e => e[0])
  }
)

export const selectAvailableProjectsById = createSelector(
  selectProjectsById,
  selectVisibleLocationIds,
  selectProjectIdsByLocation,
  (projectsById, visibleLocationIds, projectIdsByLocation) => {
    const result = {}

    visibleLocationIds.forEach(locationId => {
      projectIdsByLocation[locationId].forEach(projectId => {
        result[projectId] = projectsById[projectId]
      })
    })

    return result
  }
)

export const selectProjectOptions = createSelector(
  selectProjectIds,
  selectAvailableProjectsById,
  selectProjectVisibleById,
  (projectIds, projectsById, visibleById) => {
    return projectIds
      .map(projectId => {
        const project = projectsById[projectId]

        if (!project) {
          return null
        }

        return {
          label: project.name,
          value: projectId,
          selected: visibleById[projectId]
        }
      })
      .filter(e => e !== null)
  }
)
