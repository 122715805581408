import React from 'react'
import ReportHeader from 'components/ReportHeader'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import DropdownButton from 'components/DropdownButton'
import Button from 'components/Button'
import ScrapTrendLocations from './ScrapTrendLocations'
import ScrapTrendProductsDropdown from './ScrapTrendProductsDropdown'
import { selectVisibleLocationIds, selectLocationIds } from './selectors'
import { connect } from 'react-redux'
import { showAllLocations, hideAllLocations } from './actions'
import ScrapTrendProjectsDropdown from './ScrapTrendProjectsDropdown'

const Header = ({
  locationsCount,
  selectAllLocations,
  selectNoneLocations,
  handlePrint
}) => {
  const type = new URLSearchParams(window.location.search).get('type')

  let Filters
  switch (type) {
    case 'projects':
      Filters = ScrapTrendProjectsDropdown
      break
    case 'products':
      Filters = ScrapTrendProductsDropdown
      break
    default:
      Filters = () => {}
  }

  return (
    <FormattedMessage id='reports.scrapTrend.name'>
      {text => (
        <ReportHeader name={text} handlePrint={handlePrint}>
          <div className='report-header__navigation-item'>
            <DropdownButton
              color='default'
              className='btn--flex'
              popperConfig={{ placement: 'bottom-end' }}
              content={() => (
                <div className='multi-select-box multi-select-box multi-select-box--realization-menu'>
                  <div className='multi-select-box__actions'>
                    <Button
                      size='small'
                      color='secondary'
                      onClick={selectAllLocations}
                    >
                      <FormattedMessage id='reports.scrapTrend.selectAll' />
                    </Button>
                    <Button
                      size='small'
                      color='secondary'
                      onClick={selectNoneLocations}
                    >
                      <FormattedMessage id='reports.scrapTrend.selectNone' />
                    </Button>
                  </div>

                  <div className='multi-select-box__select'>
                    <ScrapTrendLocations />
                  </div>
                </div>
              )}
            >
              {locationsCount < 0 && (
                <FormattedMessage id='reports.scrapTrend.allLocations' />
              )}
              {locationsCount === 0 && (
                <FormattedMessage id='reports.scrapTrend.noneLocation' />
              )}
              {locationsCount > 0 && (
                <FormattedMessage
                  id='reports.scrapTrend.nLocations'
                  values={{ n: locationsCount }}
                />
              )}
            </DropdownButton>
          </div>
          <Filters />
        </ReportHeader>
      )}
    </FormattedMessage>
  )
}

Header.propTypes = {
  locationsCount: PropTypes.number,
  selectAllLocations: PropTypes.func,
  selectNoneLocations: PropTypes.func,
  handlePrint: PropTypes.func
}

const mapStateToProps = state => {
  const locationsCount = selectLocationIds(state).length
  const visibleLocationsCount = selectVisibleLocationIds(state).length

  return {
    locationsCount:
      locationsCount === visibleLocationsCount ? -1 : visibleLocationsCount
  }
}

const mapDispatchToProps = {
  selectAllLocations: showAllLocations,
  selectNoneLocations: hideAllLocations
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
